<template>

    <div>
        <CForm ref="form" class="needs-validation">

            <CRow style="margin-bottom: .5rem">

                <CCol col="6">

                    <CInput
                        label="İsim"
                        placeholder="Tanımlama içindir. Sitede görünmez"
                        type="text"
                        v-model="data.name"
                        horizontal
                        required
                        style="margin-bottom: 0rem"
                    />

                </CCol>

                <CCol col="6">
                    <CInput
                        label="Sıra"
                        type="text"
                        horizontal
                        v-model="data.priority"
                        required
                    />
                </CCol>

            </CRow>

            <CRow>

                <CCol col="6">
                    <CInput
                        label="Başlangıç Zamanı"
                        horizontal
                        type="datetime-local"
                        v-model="data.sendStartTime"
                    />
                </CCol>

                <CCol col="6">

                    <CInput
                        label="Bitiş Zamanı"
                        horizontal
                        type="datetime-local"
                        v-model="data.sendFinishTime"
                    />

                </CCol>

            </CRow>

            <CRow>

                <CCol col="6">
                    <CSelect
                        label="İçerik Tipi"
                        type="text"
                        placeholder="Seçiniz"
                        :options="contentTypes"
                        :value.sync="data.contentTypeId"
                        horizontal
                        required
                    />
                </CCol>

                <CCol col="6">

                    <CSelect
                        label="Lokasyon"
                        type="text"
                        placeholder="Seçiniz"
                        :options="publishTypes"
                        :value.sync="data.publishType"
                        horizontal
                        required
                    />

                </CCol>

            </CRow>

    </CForm>

        
</div>

</template>

<script>

    export default{
        name: "contentForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        computed:{
            contentTypes: function() {
                let data = ['İçerik Tipi Seçin']
                this.$store.getters.contentTypes.map(r => data.push({value:r.id, label: r.name}))
                return data
            },

            publishTypes: function() {
                let data = ['Lokasyon Seçin']
                this.$store.getters.contentPublishTypes.map(r => data.push({value:r.type, label: r.friendlyType}))
                return data
            },
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create'){
                    this.data = {...this.params}
                }
            },

        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>